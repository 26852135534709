import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import getEndpoints from '../api/endpoints';
import LoadingComponent from '../components/layout/LoadingComponent';
import MyDivider from '../components/layout/MyDivider';
import { Button, Divider, Icon } from 'semantic-ui-react';
import FirmaForm from '../components/forms/FirmaForm';

const NewFirma = () => {

    document.title = "Firma bearbeiten";

    const [loading, setLoading] = useState(false);
    const [initialFirmanData, setInitialFirmaData] = useState(null);

    const params = useParams();
    const firmenId = params.firmenId;

    const nav = useNavigate();
    
    useEffect(() => {
        const fetchFirmaData = async () => {
            if (firmenId) {
                setLoading(true);
                try {
                    const endpoints = await getEndpoints();
                    const data = await endpoints.getFiremenDetails(firmenId);
                    setInitialFirmaData(data);
                } catch (error) {
                    alert(JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchFirmaData();
    }, [firmenId]);

    const updateFormValues = async (formData) => {
        try {
            setLoading(true);
            formData.FirmaId = firmenId;
            const endpoints = await getEndpoints();
            await endpoints.updateFirma(firmenId, formData);
            nav(`/firmen/${firmenId}`);
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }

    const createFormValues = async (formData) => {
        try {
            setLoading(true);
            const endpoints = await getEndpoints();
            await endpoints.postNewFirma(formData);
            nav(`/`);
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }

    const handleFormSubmit = async (formData) => {
        if (firmenId) {
            await updateFormValues(formData);
        } else {
            await createFormValues(formData);
        }
    }
    if (loading) return <LoadingComponent />

    return (
        <div>
            {
                firmenId ? <Button icon labelPosition='left' onClick={() => nav(`/firmen/${firmenId}`)}>Back <Icon name='angle left' /></Button> :
                    <Button icon labelPosition='left' onClick={() => nav(`/`)}>Back <Icon name='angle left' /></Button>
            }
            <Divider hidden />
            <MyDivider title={initialFirmanData ? "Firma beartbeiten": "Neue Firma"} />
            <FirmaForm handleFormSubmit={handleFormSubmit} editValues={initialFirmanData} />
        </div>
    )
}

export default NewFirma