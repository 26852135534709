import getApiWithToken from "./api";  

export default async function getEndpoints() {
    const api = await getApiWithToken();

    return {
        postNewFirma: async (data) => {
            const response = await api.post(`/firmen`, data);
            return response.data;
        },
        updateFirma: async (addresseId, data) => {
            const response = await api.put(`/firmen/${addresseId}`, data);
            return response.data;
        },
        getOverview: async () => {
            const response = await api.get('/overview');
            return response.data;
        },
        getFiremenDetails: async (id) => {
            const response = await api.get(`/firmen/${id}`);
            return response.data;
        },
        getFiremenWebConnect: async (id) => {
            const response = await api.get(`/webconnect/${id}`);
            return response.data;
        },
        getFiremenAddressen: async (id) => {
            const response = await api.get(`/firmen/addressen/${id}`);
            return response.data;
        },
        getAddresse: async (id) => {
            const response = await api.get(`/addressen/${id}`);
            return response.data;
        },
        postNewAddresse: async (data) => {
            const response = await api.post(`/addressen`, data);
            return response.data;
        },
        updateAddresse: async (addresseId, data) => {
            const response = await api.put(`/addressen/${addresseId}`, data);
            return response.data;
        },
        deleteAddresse: async (addresseId, firmenid) => {
            const response = await api.delete(`/addressen/${firmenid}/${addresseId}`);
            return response.data;
        },
        getWebConnect: async (id) => {
            const response = await api.get(`/webconnect/single/${id}`);
            return response.data;
        },
        postNewWebConnect: async (data) => {
            const response = await api.post(`/webconnect`, data);
            return response.data;
        },
        deleteWebConnect: async (id) => {
            const response = await api.delete(`/webconnect/${id}`);
            return response.data;
        },
        updateWebConnect: async (webconnectId, data) => {
            const response = await api.put(`/webconnect/${webconnectId}`, data);
            return response.data;
        },
    };
}
