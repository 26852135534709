import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Container } from 'semantic-ui-react';
import FormikTextInput from './FormikTextInput';

const validationSchema = Yup.object().shape({
    kundenummer: Yup.string().required('Kundenummer is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    username: Yup.string().required('Username is required'),
    kennwort: Yup.string().required('Kennwort is required'),
    kennwort2: Yup.string(),
    telefon: Yup.string(),
    kommentar: Yup.string(),
});

const WebConnectForm = ({ handleFormSubmit, editValues = undefined }) => {
    const defaultValues = {
        kundenummer: '',
        email: '',
        internet: '',
        username: '',
        kennwort: '',
        kennwort2: '',
        telefon: '',
        kommentar: '',
    };

    const [formInitialValues, setFormInitialValues] = useState(defaultValues);

    useEffect(() => {
        if (editValues) {
            setFormInitialValues(editValues);
        }
    }, [editValues]);

    return (
        <Container >
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize  
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group widths='equal'>
                            <FormikTextInput
                                name="kundenummer"
                                label="Kundenummer"
                                placeholder="Kundenummer" />
                            <FormikTextInput
                                name="email"
                                label="Email"
                                placeholder="Email" />
                            <FormikTextInput
                                name="internet"
                                label="Internet"
                                placeholder="Internet" />
                            <FormikTextInput
                                name="telefon"
                                label="Tel."
                                placeholder="Tel." />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <FormikTextInput
                                name="username"
                                label="Username"
                                placeholder="Username" />
                            <FormikTextInput
                                name="kennwort"
                                label="Kennwort"
                                placeholder="Kennwort" />
                            <FormikTextInput
                                name="kennwort2"
                                label="Kennwort2"
                                placeholder="Kennwort2" />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <FormikTextInput
                                name="kommentar"
                                label="Kommentar"
                                placeholder="Kommentar" />
                        </Form.Group>

                        <Button type='submit' secondary>Submit</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default WebConnectForm;