import React, { useEffect, useState } from 'react'
import WebConnectForm from '../components/forms/WebConnectForm'
import getEndpoints from '../api/endpoints';
import LoadingComponent from '../components/layout/LoadingComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Header, Icon } from 'semantic-ui-react';
import MyDivider from '../components/layout/MyDivider';
import AddressForm from '../components/forms/AddressForm';


const NewAddress = () => {

    document.title = "Addresse bearbeiten";

    const [loading, setLoading] = useState(false);
    const [initialAddressData, setInitialAddressData] = useState(null);

    const params = useParams();
    const firmenId = params.firmenId;
    const addressId = params.addressId; 

    const nav = useNavigate();

    
    useEffect(() => {
        const fetchAddressData = async () => {
            if (addressId) {
                setLoading(true);
                try {
                    const endpoints = await getEndpoints();
                    const data = await endpoints.getAddresse(addressId);
                    setInitialAddressData(data);
                } catch (error) {
                    alert(JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchAddressData();
    }, [addressId]);

    const updateFormValues = async (formData) => {
        try {
            setLoading(true);
            const endpoints = await getEndpoints();
            await endpoints.updateAddresse(addressId, formData);
            nav(`/firmen/${firmenId}`);
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }

    const createFormValues = async (formData) => {
        try {
            setLoading(true);
            formData.FirmaId = firmenId;
            const endpoints = await getEndpoints();
            await endpoints.postNewAddresse(formData);
            nav(`/firmen/${firmenId}`);
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }

    const handleFormSubmit = async (formData) => {
        if (addressId) {
            await updateFormValues(formData);
        } else {
            await createFormValues(formData);
        }
    }
    if (loading) return <LoadingComponent />

    return (
        <div>
            <Button icon labelPosition='left' onClick={() => nav(`/firmen/${firmenId}`)}>Back <Icon name='angle left' /></Button>
            <Divider hidden />
            <MyDivider title={initialAddressData ? "Addresse beartbeiten": "Neue Addresse"} />
            <AddressForm handleFormSubmit={handleFormSubmit} editValues={initialAddressData} />
        </div>
    )
}

export default NewAddress