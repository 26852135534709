import React from 'react'
import { Table } from 'semantic-ui-react'

const FirmenDetailsTable = ({ data }) => {

    if (!data) return null


    return (
        <div>
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Kundenummer</Table.HeaderCell>
                    <Table.HeaderCell>Kennwort</Table.HeaderCell>
                    <Table.HeaderCell>Beschreibung</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{data.Kundenummer}</Table.Cell>
                    <Table.Cell>{data.Kennwort}</Table.Cell>
                    <Table.Cell>{data.Beschreibung}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Kontonummer</Table.HeaderCell>
                        <Table.HeaderCell>Bankname</Table.HeaderCell>
                        <Table.HeaderCell>BLZ</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{data.Kontonummer}</Table.Cell>
                        <Table.Cell>{data.Bankname}</Table.Cell>
                        <Table.Cell>{data.BLZ}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    )
}

export default FirmenDetailsTable