import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Container } from 'semantic-ui-react';
import FormikTextInput from './FormikTextInput';

const validationSchema = Yup.object().shape({
    FaName: Yup.string().required('StrasseNr is required'),
    FaNummer: Yup.string().required('PLZ is required'),
});

const FirmaForm = ({ handleFormSubmit, editValues = undefined }) => {
    const defaultValues = {
        BLZ: '',
        Bankname: '',
        Beschreibung: '',
        FaName: '',
        FaNummer: '',
        Kennwort: '',
        Kontonummer: '',
        Kundenummer: ''
    };
    const [formInitialValues, setFormInitialValues] = useState(defaultValues);

    useEffect(() => {
        if (editValues) {
            console.log("Setting setFormInitialValues:");
            setFormInitialValues(editValues);
        }
    }, [editValues]);

    return (
        <Container >
            {formInitialValues
                &&
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                    enableReinitialize
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths='equal'>
                                <FormikTextInput
                                    name="FaName"
                                    label="Fa. Name"
                                    placeholder="Fa. Name" />
                                <FormikTextInput
                                    name="FaNummer"
                                    label="Fa. Nummer"
                                    placeholder="Fa. Nummer" />
                                <FormikTextInput
                                    name="Kundenummer"
                                    label="Kundenummer"
                                    placeholder="Kundenummer" />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <FormikTextInput
                                    name="Bankname"
                                    label="Bank"
                                    placeholder="Bank" />
                                <FormikTextInput
                                    name="BLZ"
                                    label="BLZ"
                                    placeholder="BLZ" />
                            </Form.Group>

                            <FormikTextInput
                                name="Beschreibung"
                                label="Beschreibung"
                                placeholder="Beschreibung" />

                            <Button type='submit' secondary>Submit</Button>
                        </Form>
                    )}
                </Formik>
            }
        </Container>
    )
}

export default FirmaForm