import React from 'react'
import { Button, Card, Table } from 'semantic-ui-react'
import DeleteEditButttons from '../layout/DeleteEditButttons'

const FirmenAddresseCard = ({ data, onDeleteAddress, onEditAddress }) => {
    return (
        <Table.Row >
            <Table.Cell>{data.Ort} - {data.StrasseNr}</Table.Cell>
            <Table.Cell>{data.PLZ}, {data.Bundesland}, {data.Land}</Table.Cell>
            <Table.Cell>{data.TelefonGeschaeftlich}</Table.Cell>
            <Table.Cell>{data.Fax}</Table.Cell>
            <Table.Cell>{data.Fax2}</Table.Cell>
            <Table.Cell>{data.Website}</Table.Cell>
            <Table.Cell>{data.Kommentar}</Table.Cell>
            <Table.Cell>
                <DeleteEditButttons id={data.Id} onDelete={() => onDeleteAddress(data.AddresseID)} onEdit={() => onEditAddress(data.AddresseID)} />
            </Table.Cell>
          </Table.Row>
    )
}

export default FirmenAddresseCard