import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Container } from 'semantic-ui-react';
import FormikTextInput from './FormikTextInput';

const validationSchema = Yup.object().shape({
    StrasseNr: Yup.string().required('StrasseNr is required'),
    PLZ: Yup.string().required('PLZ is required'),
});

const AddressForm = ({ handleFormSubmit, editValues = undefined }) => {

    const defaultValues = {
        TelefonGeschaeftlich: '',
        Fax: '',
        Fax2: '',
        Website: '',
        Kommentar: '',
        StrasseNr: '',
        PLZ: '',
        Ort: '',
        Bundesland: '',
        Land: '',
        Zusazt: '',
    };

    const [formInitialValues, setFormInitialValues] = useState(defaultValues);

    useEffect(() => {
        if (editValues) {
            setFormInitialValues(editValues);
        }
    }, [editValues]);

    return (
        <Container >
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize  
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group widths='equal'>
                            <FormikTextInput
                                name="StrasseNr"
                                label="StrasseNr"
                                placeholder="StrasseNr" />
                            <FormikTextInput
                                name="PLZ"
                                label="PLZ"
                                placeholder="PLZ" />
                            <FormikTextInput
                                name="Ort"
                                label="Ort"
                                placeholder="Ort" />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <FormikTextInput
                                name="Bundesland"
                                label="Bundesland"
                                placeholder="Bundesland" />

                            <FormikTextInput
                                name="Land"
                                label="Land"
                                placeholder="Land" />

                        </Form.Group>

                        <FormikTextInput
                            name="Website"
                            label="Website"
                            placeholder="Website" />
                        <Form.Group widths='equal'>
                            <FormikTextInput
                                name="TelefonGeschaeftlich"
                                label="Tel."
                                placeholder="Te." />

                            <FormikTextInput
                                name="Fax"
                                label="Fax"
                                placeholder="Fax" />
                        </Form.Group>

                        <Button type='submit' secondary>Submit</Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default AddressForm