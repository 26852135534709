import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useField } from 'formik';

const FormikTextInput = ({ label, placeholder, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <Form.Field
            control={Input}
            label={label}
            placeholder={placeholder}
            error={meta.touched && meta.error ? meta.error : null}
        >
            <Input {...field} {...props} />
        </Form.Field>
    );
};


export default FormikTextInput;
