import React, { useEffect, useState } from 'react'
import { Container, Divider, Header, Table } from 'semantic-ui-react'
import FirmenOverviewTable from '../components/FirmenOverviewTable';
import LoadingComponent from '../components/layout/LoadingComponent';
import getEndpoints from '../api/endpoints';
import MyDivider from '../components/layout/MyDivider';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    document.title = "Drahtesel";

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const nav = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            setData(null);
            const Endpoints = await getEndpoints();
            const data = await Endpoints.getOverview();
            setData(data);
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleNew = () => {
        nav("/firmen/new")
    }

    if (loading) return <LoadingComponent />
    if (!data) return <p>No data fetched</p>;
    return (
        <div>
            <MyDivider title="Firmen" handleNew={handleNew}/>
            <Divider />
            <FirmenOverviewTable tableData={data} />
        </div>
    )
}

export default Home