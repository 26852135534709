import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../layout/LoadingComponent';
import MyDivider from '../layout/MyDivider';
import FirmenAddresseCard from './FirmenAddresseCard';
import { Divider, Table } from 'semantic-ui-react';
import getEndpoints from '../../api/endpoints';

const FirmenAddressen = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();
    const firmenId = params.firmenId;

    const nav = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            setData(null);
            const Endpoints = await getEndpoints();
            const data = await Endpoints.getFiremenAddressen(firmenId);
            setData(data);
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleNewAddress = () => {
        nav(`/addresse/new/${firmenId}`)
    }

    const handleEditAddress = (idAddress) => {
        nav(`/addresse/edit/${firmenId}/${idAddress}`)
    }

    const onDeleteAddress = async (idAddress) => {
        try {
            setLoading(true);
            const Endpoints = await getEndpoints();
            await Endpoints.deleteAddresse(idAddress, firmenId);
            fetchData();
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <LoadingComponent />
    if (!data) return <p>No data fetched</p>;
    return (
        <div>
            <Divider hidden />
            <MyDivider title="Addressen" handleNew={handleNewAddress} />
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Ort - Straße Nr.</Table.HeaderCell>
                        <Table.HeaderCell>PLZ, Bundesland, Land</Table.HeaderCell>
                        <Table.HeaderCell>Telefon Geschäftlich</Table.HeaderCell>
                        <Table.HeaderCell>Fax</Table.HeaderCell>
                        <Table.HeaderCell>Fax 2</Table.HeaderCell>
                        <Table.HeaderCell>Website</Table.HeaderCell>
                        <Table.HeaderCell>Kommentar</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data.map((data, index) => (
                            <FirmenAddresseCard key={index} data={data} onDeleteAddress={onDeleteAddress} onEditAddress={handleEditAddress} />
                        ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export default FirmenAddressen