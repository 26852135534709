import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../layout/LoadingComponent';
import MyDivider from '../layout/MyDivider';
import FirmenWebAccessCard from './FirmenWebAccessCard';
import { Divider, List, Table } from 'semantic-ui-react';
import getEndpoints from '../../api/endpoints';

const FirmenWebConnect = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();
    const firmenId = params.firmenId;

    const nav = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            setData(null);
            const Endpoints = await getEndpoints();
            const data = await Endpoints.getFiremenWebConnect(firmenId);
            setData(data);
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleNewWebConnect = () => {
        nav(`/webconnect/new/${firmenId}`)
    }

    const handleEditWebConnect = (idWebaccess) => {
        nav(`/webconnect/edit/${firmenId}/${idWebaccess}`)
    }

    const onDeleteWebConnection = async (idWebaccess) => {
        try {
            setLoading(true);
            const Endpoints = await getEndpoints();
            await Endpoints.deleteWebConnect(idWebaccess);
            fetchData();
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <LoadingComponent />
    if (!data) return <p>No data fetched</p>;
    return (
        <div>
            <Divider hidden />
            <MyDivider title="Web Connections" handleNew={handleNewWebConnect} />
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Customer Number</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Internet</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>Password</Table.HeaderCell>
                        <Table.HeaderCell>Password 2</Table.HeaderCell>
                        <Table.HeaderCell>Phone</Table.HeaderCell>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data.map((data, index) => (
                            <FirmenWebAccessCard key={index} data={data} 
                            onDeleteWebConnection={onDeleteWebConnection} 
                            onEditWebConnection={handleEditWebConnect}/>
                        ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export default FirmenWebConnect