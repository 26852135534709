import React from 'react'
import { Button, Table } from 'semantic-ui-react'
import DeleteEditButttons from '../layout/DeleteEditButttons'

const FirmenWebAccessCard = ({ data, onDeleteWebConnection, onEditWebConnection }) => {
    console.log("FirmenWebAccessCard", data);
    return (
        <Table.Row>
            <Table.Cell>{data.Kundenummer}</Table.Cell>
            <Table.Cell>{data.Email}</Table.Cell>
            <Table.Cell>{data.Internet}</Table.Cell>
            <Table.Cell>{data.Username}</Table.Cell>
            <Table.Cell>{data.Kennwort}</Table.Cell>
            <Table.Cell>{data.Kennwort2}</Table.Cell>
            <Table.Cell>{data.Telefon}</Table.Cell>
            <Table.Cell>{data.Kommentar}</Table.Cell>
            <Table.Cell>
                <DeleteEditButttons id={data.Id} 
                    onDelete={() => onDeleteWebConnection(data.idWebaccess)} 
                    onEdit={() => onEditWebConnection(data.idWebaccess)} />
            </Table.Cell>
        </Table.Row>
        )
}

        export default FirmenWebAccessCard