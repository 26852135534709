import React from 'react'
import { Button } from 'semantic-ui-react'

const DeleteEditButttons = ({id, onDelete, onEdit}) => {
  return (
    <div>
        <Button icon="trash" negative circular onClick={() => onDelete(id)}/>
        <Button icon="edit" primary circular onClick={() => onEdit(id)}/>
    </div>
  )
}

export default DeleteEditButttons