import React from 'react'
import { Button, Divider, Header } from 'semantic-ui-react'

const MyDivider = ({ title, handleNew, handleEdit }) => {
    return (
        <div>
            <div className='mydivider-header'>
                <Header as='h4'>{title}</Header>
                {
                    handleNew &&
                    <Button onClick={handleNew} color='green'>Neu</Button>
                }
                {
                    handleEdit &&
                    <Button onClick={handleEdit} color='teal'>Bearbeiten</Button>
                }
            </div>
            <Divider />
        </div>
    )
}

export default MyDivider