import React, { useEffect, useState } from 'react'
import WebConnectForm from '../components/forms/WebConnectForm'
import getEndpoints from '../api/endpoints';
import LoadingComponent from '../components/layout/LoadingComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Header, Icon } from 'semantic-ui-react';
import MyDivider from '../components/layout/MyDivider';


const NewWebConnect = () => {

    document.title = "Anbindung bearbeiten";

    const [loading, setLoading] = useState(false);
    const [initialWebConnectData, setInitialWebConnectData] = useState(null);

    const params = useParams();
    const firmenId = params.firmenId;
    const webconnectId = params.webconnectId; 

    const nav = useNavigate();

    
    useEffect(() => {
        const fetchWebConnectData = async () => {
            if (webconnectId) {
                console.log("webconnectId", webconnectId);
                setLoading(true);
                try {
                    const endpoints = await getEndpoints();
                    const data = await endpoints.getWebConnect(webconnectId);
                    setInitialWebConnectData(data);
                } catch (error) {
                    alert(JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchWebConnectData();
    }, [webconnectId]);

    const updateFormValues = async (formData) => {
        try {
            setLoading(true);
            const endpoints = await getEndpoints();
            await endpoints.updateWebConnect(webconnectId, formData);
            nav(`/firmen/${firmenId}`);
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }

    const createFormValues = async (formData) => {
        try {
            setLoading(true);
            formData.firmenId = firmenId;
            const endpoints = await getEndpoints();
            await endpoints.postNewWebConnect(formData);
            nav(`/firmen/${firmenId}`);
        } catch (error) {
            alert(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }

    const handleFormSubmit = async (formData) => {
        if (webconnectId) {
            await updateFormValues(formData);
        } else {
            await createFormValues(formData);
        }
    }
    if (loading) return <LoadingComponent />

    return (
        <div>
            <Button icon labelPosition='left' onClick={() => nav(`/firmen/${firmenId}`)}>Back <Icon name='angle left' /></Button>
            <Divider hidden />
            <MyDivider title={initialWebConnectData ? "Webkonto beartbeiten": "Neue Webkonto"} />
            <WebConnectForm handleFormSubmit={handleFormSubmit} editValues={initialWebConnectData}/>
        </div>
    )
}

export default NewWebConnect