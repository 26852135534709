import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../components/layout/LoadingComponent';
import MyDivider from '../components/layout/MyDivider';
import FirmenAddressen from '../components/firmen/FirmenAddressen';
import FirmenWebConnect from '../components/firmen/FirmenWebConnect';
import getEndpoints from '../api/endpoints';
import FirmenDetailsTable from '../components/firmen/FirmenDetailsTable';

const FirmenDetails = () => {

    document.title = "Details";

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const params = useParams();
    const firmenId = params.firmenId;

    const nav = useNavigate();

    const fetchData = async () => {
        try {
            setLoading(true);
            setData(null);
            const Endpoints = await getEndpoints();
            const data = await Endpoints.getFiremenDetails(firmenId);
            setData(data);
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleEdit = () => {
        nav(`/firmen/edit/${firmenId}`);
    }

    if (loading) return <LoadingComponent />
    if (!data) return <p>No data fetched</p>;

    return (
        <>
            <MyDivider title={data.FaName} handleEdit={handleEdit} />
            <FirmenDetailsTable data={data} />
            <FirmenAddressen />
            <FirmenWebConnect />
        </>
    )
}

export default FirmenDetails