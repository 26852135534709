
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from './components/layout/NavBar';
import Home from './pages/Home';
import { Container } from 'semantic-ui-react';
import FirmenDetails from './pages/FirmenDetails';
import UserProfile from './pages/UserProfile';
import Login from './pages/Login';
import { UserAuthContextProvider } from './context/UserAuthContext';
import ProtectedRoute from './components/layout/ProtectedRoute';
import NewWebConnect from './pages/NewWebConnect';
import NewAddress from './pages/NewAddress';
import NewFirma from './pages/NewFirma';

function MainApp() {
    return (
        <div>
            <UserAuthContextProvider>
                <Router>
                    <Navbar />
                    <div style={{ marginTop: '7em', paddingLeft: '2em', paddingRight: '2em', paddingBottom: '7em' }}>
                        <Routes>
                            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                            <Route path="/firmen/:firmenId" element={<ProtectedRoute><FirmenDetails /></ProtectedRoute>} />
                            <Route path="/firmen/new" element={<ProtectedRoute><NewFirma /></ProtectedRoute>} />
                            <Route path="/firmen/edit/:firmenId" element={<ProtectedRoute><NewFirma /></ProtectedRoute>} />
                            <Route path='/profile' element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                            
                            <Route path="/webconnect/new/:firmenId" element={<ProtectedRoute><NewWebConnect /></ProtectedRoute>} />
                            <Route path="/webconnect/edit/:firmenId/:webconnectId" element={<ProtectedRoute><NewWebConnect /></ProtectedRoute>} />
                            <Route path="/addresse/new/:firmenId" element={<ProtectedRoute><NewAddress /></ProtectedRoute>} />
                            <Route path="/addresse/edit/:firmenId/:addressId" element={<ProtectedRoute><NewAddress /></ProtectedRoute>} />

                            <Route path='/login' element={<Login />} />
                        </Routes>
                    </div>
                </Router>
            </UserAuthContextProvider>
        </div >
    );
}

export default MainApp;
