import React, { useMemo } from 'react'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';


function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}) {
    return (
        <Input
            value={globalFilter || ''}
            onChange={e => {
                setGlobalFilter(e.target.value || undefined); // Set the global filter value
            }}
            placeholder={`Suchen...`}
        />
    )
}


const TheTable = ({ columns, data }) => {

    const tableInstance = useTable({ columns, data, initialState: { pageSize: 20, pageIndex: 0 }, autoResetPage: false },
        useGlobalFilter, useFilters, useSortBy, usePagination, );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        //rows,
        prepareRow,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        gotoPage,
        state: { pageIndex, globalFilter },
    } = tableInstance

    return (
        <div>
            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <Table celled {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map(headerGroup => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Table.HeaderCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <Icon name='angle up' /> : <Icon name='angle down' />) : ''}
                                    </span>
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <Table.Row {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                                ))}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
            <Button.Group>
                <Button icon='angle double left' onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Button icon='angle left' onClick={() => previousPage()} disabled={!canPreviousPage} />
                <Button icon='angle right' onClick={() => nextPage()} disabled={!canNextPage} />
                <Button icon='angle double right' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
            </Button.Group>
            <div>
                <span>Page {pageIndex + 1} from {pageCount}</span>
            </div>
        </div>
    );
}

const FirmenOverviewTable = ({ tableData }) => {

    const nav = useNavigate();


    const columns = useMemo(
        () => [
            {
                Header: 'Firmennr.',
                accessor: 'FaNummer',
                sortType: 'basic',
            },
            {
                Header: 'Firmenname',
                accessor: 'FaName',
                sortType: 'basic',
            },
            {
                Header: 'Webshop',
                accessor: 'Webshop',
                sortType: 'basic',
                Cell: ({ row }) => (
                    row.original.Webshop ? (
                        <a href={row.original.Webshop} target="_blank" rel="noopener noreferrer">
                            {row.original.Webshop}
                        </a>
                    ) : null
                )
            },
            {
                Header: 'Kundennummer',
                accessor: 'Kundenummer',
                sortType: 'basic',
            },
            {
                Header: 'Zahl der Adressen',
                accessor: 'address_count',
                sortType: 'basic',
            },
            {
                Header: 'Zahl der Webzugriffe',
                accessor: 'web_access_count',
                sortType: 'basic',
            },
            {
                Header: 'Actions',
                Cell: ({ row }) => {
                    // Access the full row object
                    const x = row.original;

                    return (
                        <>
                            <Button title="Berichtdetails ansehen" size='tiny' circular icon secondary onClick={() => onViewDetails(x.FirmenID)}>
                                <Icon name='eye' />
                            </Button>
                        </>
                    );
                }
            }
        ],
        []
    );

    const onViewDetails = async (firmenId) => {
        nav(`/firmen/${firmenId}`);
    }


    if (!tableData || tableData.length === 0) {
        return (
            <div>
                <p>Keine Berichte vorhanden</p>
            </div>
        )
    }

    return (
        <div>
            <TheTable columns={columns} data={tableData} />
        </div>
    )
}

export default FirmenOverviewTable